import { Input, InputProps } from "@chakra-ui/react";
import React from "react";
 
export const PrimaryInput: React.FC<InputProps> = ({ ...props}) => {
  return <Input {...props}
  borderWidth="2px"
  borderRadius="md"
  boxShadow="0px 10px 10px rgba(0, 0, 0, 0.2)"
  textAlign="center"
  bg="secondary.500"
  color="primary.50"
  border={"none"}
  />;
};
