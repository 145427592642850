import { Select } from "@chakra-ui/react";
import React from "react";
import { useTheme } from "../contexts/ThemeContext";
import { ThemeName, themes } from "../theme";
const ThemeSelector = () => {
  const { switchTheme, themeName } = useTheme();

  return (
    <Select
      value={themeName}
      onChange={(e) => switchTheme(e.target.value as ThemeName)}
      width="200px"
      mt={4}
    >
      {Object.keys(themes).map((theme) => (
        <option key={theme} value={theme} >
          {theme.charAt(0).toUpperCase() + theme.slice(1)}
        </option>
      ))}
    </Select>
  );
};

export default ThemeSelector;
