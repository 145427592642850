import {  Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import ThemeSelector from "./ThemeSelector";
export const Header: React.FC = () => {
  return (
    <Flex
      flexDir={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      h={"full"}
      bg={"primary.500"}
      color="secondary.500"
    >
      <Spacer></Spacer>
      <ThemeSelector />
    </Flex>
  );
};
