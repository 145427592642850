import { extendTheme } from "@chakra-ui/react";

export type ThemeName = "forest";

const themes: Record<ThemeName, any> = {
  forest: {
    colors: {
      primary: {
        50: "#e6f4eb",
        100: "#cde4d6",
        200: "#9ec3ae",
        300: "#75a58e",
        400: "#4c866e",
        500: "#2D6A4F",
        600: "#265a44",
        700: "#1f4a39",
        800: "#183a2e",
        900: "#112a23",
      },
      secondary: {
        50: "#f4f9e6",
        100: "#e4f0c5",
        200: "#c9df8c",
        300: "#b0d162",
        400: "#97c338",
        500: "#A7C957",
        600: "#8fa946",
        700: "#758736",
        800: "#5a6627",
        900: "#3f4419",
      } ,
      gray: {
        50: "#f7fafc",
        100: "#edf2f7",
        200: "#e2e8f0",
        300: "#cbd5e0",
        400: "#a0aec0",
        500: "#718096",
        600: "#4a5568",
        700: "#2d3748",
        800: "#1a202c",
        900: "#171923",
      }
     
    },
  } 
};

const createTheme = (themeName: ThemeName) =>
  extendTheme({
    colors: themes[themeName]["colors"],
    components: {
      Button: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "md",
        },
        variants: {
          solid: {
            bg: "primary.500",
            color: "primary.50",
            _hover: { bg: "primary.400", color: "primary.50" },
          },
          outline: {
            borderColor: "primary.500",
            color: "primary.500",
            _hover: { bg: "primary.100", color: "primary.700" },
          },
        },
      },
      Input: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "md",
        },
        variants: {
          solid: {
            bg: "primary.500",
            color: "primary.50",
            _hover: { bg: "primary.400", color: "primary.50",border:"none" },
          },
          outline: {
            borderColor: "primary.500",
            color: "primary.500",
            _hover: { bg: "primary.100" },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          fontWeight: "bold",
          borderRadius: "md" ,
          color: "gray.900",
        },
        },
      Heading: {
        baseStyle: {
          fontWeight: "bold",
          color: "primary.500",
          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif"
        },
        sizes: {
          xl: { fontSize: "4xl" },
          lg: { fontSize: "3xl" },
          md: { fontSize: "2xl" },
          sm: { fontSize: "xl" },
        },
        variants: {
          primary: {
            color: "gray.900",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" 
          },
          secondary: {
            color: "gray.600",
            fontWeight: "semibold",
          },
        },
        defaultProps: {
          size: "md",
          variant: "primary",
        },
      },
    },
    styles: {
      global: {
        "html, body": {
          backgroundColor: "gray.900",
          color: "gray.800",
          fontFamily: "'Inter', sans-serif",
          lineHeight: "base",
        },
      },
    },
  });

export { createTheme, themes };
