import { Box, Grid, GridItem, Heading } from "@chakra-ui/react";
import React from "react";
import { DoghnutData } from "../components/DoughnutGraph";
import { COLORS } from "../utils/toolbox";
import {
  AutoConsommationIndicator,
  EnergyDistribution,
} from "../components/AutoConsommationIndicatorComponent";
import { PrimaryButton } from "../components/PrimaryButton";

interface AutoConsommationCardProps {
  graphData: DoghnutData;
  title: string;
  energyDistribution: EnergyDistribution[];
}

const AutoConsommationCard: React.FC<AutoConsommationCardProps> = ({
  graphData,
  title,
  energyDistribution,
}) => {
  return (
    <GridItem
      w="full"
      rowSpan={{ base: 1, md: 2 }}
      colSpan={{
        base: 2,
        md: 1,
      }}
    >
      <Box h="400px">
        <AutoConsommationIndicator
          graphData={graphData}
          title={title}
          energyDistribution={energyDistribution}
        />
      </Box>
    </GridItem>
  );
};

export const AutoConsommationPage: React.FC = () => {
  const labels: string[] = ["prod", "conso"];

  const doghnutDataProd: DoghnutData = {
    backgroundColor: [COLORS.PROD, COLORS.CONSO],
    labels: labels,
    datas: [300, 100],
  };

  const doghnutDataConso: DoghnutData = {
    backgroundColor: [COLORS.PROD, COLORS.CONSO],
    labels: labels,
    datas: [60, 120],
  };

  const prodEnergyDistribution: EnergyDistribution[] = [
    { value: "300 kWh", color: COLORS.PROD, label: "Export" },
    { value: "100 kWh", color: COLORS.CONSO, label: "Consommé" },
  ];

  const consoEnergyDistribution: EnergyDistribution[] = [
    { value: "60 kWh", color: COLORS.PROD, label: "Produit sur place" },
    { value: "120 kWh", color: COLORS.CONSO, label: "Provenant du réseau" },
  ];

  return (
    <Box w={"full"} h={"full"} p={2}>
      <Heading
        variant={"primary"}
        textAlign={"center"}
        size={{ base: "sm", md: "md" }}
      >
        Si vous solarisez toute votre toiture:
      </Heading>
      <Box>
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={{ base: 4, md: 10 }}
          h="full"
          justifyItems="center"
          alignContent="center"
          alignItems="center"
          py={10}
        >
          <AutoConsommationCard
            graphData={doghnutDataConso}
            title="Consommation"
            energyDistribution={consoEnergyDistribution}
          />
          <AutoConsommationCard
            graphData={doghnutDataProd}
            title="Production"
            energyDistribution={prodEnergyDistribution}
          />
        </Grid>
      </Box>
      <Box display="flex" justifyContent="end" m={2}>
        <PrimaryButton
          type="submit"
          px={{ base: 8, md: 16 }}
          py={{ base: 4, md: 8 }}
        >
          Suivant
        </PrimaryButton>
      </Box>
    </Box>
  );
};
