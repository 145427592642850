/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react"; 
import { 
  fetchBaseQuery,
} from "@reduxjs/toolkit/query"; 
const BASE_URL="http://127.0.0.1:8456/api/v1/"
export interface CalculateDataRequestBody {
    type:string,
    pv_power:number,
    angle:number,
    azimuth:number,
    battery_capacity:number,
    battery_power:number,
    latitude : number,
    longitude : number,
    conso:number;
    prices:number[]
}
export const calculatorServiceApi = createApi({
  reducerPath: "calculatorServiceApi",
  baseQuery:fetchBaseQuery({
    baseUrl: BASE_URL,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareHeaders: (headers: any) => {
      headers.set("Accept", "application/json");
      return headers;
    }, 
  }),
  endpoints: (builder) => ({
    calculateData: builder.mutation<unknown, CalculateDataRequestBody >({
      query: (body) => ({
        url: `solar_data`,
        body,
        method: "POST"
      }),
    })
})});

export const { 
useCalculateDataMutation
} = calculatorServiceApi;
