import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  HStack,
  Input,
  Text,
  useRadioGroup,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import React, { useEffect } from "react";
import { RadioCard } from "../components/RadioCard";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "../components/PrimaryButton";
import { PrimaryInput } from "../components/PrimaryInput";
interface Profil {
  name: string;
  image: string;
  description: string;
}
type TarifType = "hp_hc" | "5cadrans" | "4cadrans" | "base";
interface PowerOption {
  power: string;
  invoice: string;
}
interface TarifOption {
  value: string;
  viewValue: string;
}
interface ConsumptionData {
  [key: string]: string | object | undefined;
  profil: string;
  power: string;
  tarif: string;
  base: { base: number };
  hp_hc: {
    hp: number;
    hc: number;
  };
  "4cadrans": {
    hpbs: number;
    hphs: number;
    hchs: number;
    hcbs: number;
  };
  "5cadrans": {
    hpbs: number;
    hphs: number;
    hchs: number;
    hcbs: number;
    hp: number;
  };
}
export const ConsumptionPage: React.FC = () => {
  const profilOptions: Profil[] = [
    {
      name: "Profil1",
      image: "",
      description:
        "Consommation adaptée aux entreprises Dotées de groupe froid",
    },
    {
      name: "Profil2",
      image: "",
      description:
        "Consommation adaptée aux entreprises Dotées d’équipement froid et un profil mixte Ex: Restaurant/ Hotel",
    },
    {
      name: "Profil3",
      image: "",
      description:
        "Consommation adaptée aux entreprises du tertiaire avec une activité en semaine Ex: Tertiaire/Bureau d’entreprise",
    },
  ];
  const powerOptions: PowerOption[] = [
    {
      power: "30MWh",
      invoice: "4500HTVA",
    },
    {
      power: "75MWh",
      invoice: "4500HTVA",
    },
    {
      power: "150MWh",
      invoice: "4500HTVA",
    },
    {
      power: "300MWh",
      invoice: "4500HTVA",
    },
  ];
  const tarifOptions: TarifOption[] = [
    {
      value: "base",
      viewValue: "BASE",
    },
    {
      value: "hp_hc",
      viewValue: "HP/HC",
    },
    {
      value: "4cadrans",
      viewValue: "4CADRANS",
    },
    {
      value: "5cadrans",
      viewValue: "5CADRANS",
    },
  ];
  const {
    getValues,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ConsumptionData>({
    defaultValues: {
      power: "30MWh",
      profil: "Profil2",
      tarif: "base",
      hp_hc: {
        hp: 1,
        hc: 2,
      },
      base: {
        base: 12,
      },
      "4cadrans": {
        hcbs: 14,
        hchs: 15,
        hpbs: 5,
        hphs: 9,
      },
      "5cadrans": {
        hcbs: 14,
        hchs: 15,
        hpbs: 5,
        hphs: 9,
        hp: 12,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    alert(JSON.stringify(data));
  };
  const {
    getRootProps: getRootPropsProfil,
    getRadioProps: getRadioPropsProfil,
  } = useRadioGroup({
    name: "profils",
    defaultValue: "Profil2",
    onChange: (value: string) => setValue("profil", value),
  });
  const { getRootProps: getRootPropsPower, getRadioProps: getRadioPropsPower } =
    useRadioGroup({
      name: "powers",
      defaultValue: "30MWh",
      onChange: (value: string) => setValue("power", value),
    });
  const {
    getRootProps: getRootPropsTarifs,
    getRadioProps: getRadioPropsTarifs,
  } = useRadioGroup({
    name: "tarifs",
    defaultValue: "base",
    onChange: (value: "hp_hc" | "4cadrans" | "5cadrans" | "base") => {
      setValue("tarif", value);
    },
  });

  const groupProfils = getRootPropsProfil();
  const groupPower = getRootPropsPower();
  const groupTarif = getRootPropsTarifs();

  return (
    <Box p={2} flex={1}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 4,
          flex: 1,
          height: "100%",
        }}
      >
        <FormControl as="fieldset" mb={4}>
          <FormLabel as="legend" my={8}>
            <Heading
              variant={"primary"}
              textAlign={"center"}
              size={{ base: "sm", md: "md" }}
            >
              {" "}
              Profil de consommation
            </Heading>
          </FormLabel>
          <Flex
            {...groupProfils}
            flexDirection={"row"}
            wrap={"wrap"}
            gap={4}
            justifyContent={"space-around"}
          >
            {profilOptions.map((item: Profil, key: number) => {
              const radio = getRadioPropsProfil({ value: item.name });
              return (
                <VStack h={"auto"} gap={5} key={key} flex={1}>
                  <RadioCard {...radio}>
                    <Box
                      aspectRatio={"auto"}
                      justifyContent={"center"}
                      display={"flex"}
                      alignItems={"center"}
                      h={"auto"}
                      w={"150px"}
                      height={"150px"}
                    >
                      {item.name}
                    </Box>
                  </RadioCard>
                  <Text
                    color={"gray.500"}
                    justifyContent={"center"}
                    textAlign={"center"}
                  >
                    {item.description}
                  </Text>
                </VStack>
              );
            })}
          </Flex>
        </FormControl>

        <FormControl as="fieldset" mb={4}>
          <FormLabel as="legend" my={8}>
            <Heading
              variant={"primary"}
              textAlign={"center"}
              size={{ base: "sm", md: "md" }}
            >
              Consommation Annuelle
            </Heading>
          </FormLabel>
          <Flex
            {...groupPower}
            flexDirection={"row"}
            wrap={"wrap"}
            gap={4}
            justifyContent={"space-around"}
          >
            {powerOptions.map((item: PowerOption, key: number) => {
              const radio = getRadioPropsPower({ value: item.power });
              return (
                <VStack key={key}>
                  <Box>
                    <RadioCard {...radio}>
                      <VStack
                        aspectRatio={"auto"}
                        justifyContent={"center"}
                        display={"flex"}
                        alignItems={"center"}
                        h={"full"}
                        w={"full"}
                      >
                        <Text size="md"> {item.power}</Text>
                        <Text>Facture annuelle: {item.invoice}</Text>
                      </VStack>
                    </RadioCard>
                  </Box>
                </VStack>
              );
            })}
          </Flex>
        </FormControl>
        <FormControl as="fieldset" mb={4}>
          <FormLabel as="legend" my={8}>
            <Heading
              variant={"primary"}
              textAlign={"center"}
              size={{ base: "sm", md: "md" }}
            >
              {" "}
              Type de tarification
            </Heading>
          </FormLabel>
          <Flex
            {...groupTarif}
            flexDirection={"row"}
            wrap={"wrap"}
            gap={4}
            justifyContent={"space-around"}
          >
            {tarifOptions.map((item: TarifOption, key: number) => {
              const radio = getRadioPropsTarifs({ value: item.value });
              return (
                <VStack key={key}>
                  <Box>
                    <RadioCard {...radio}>
                      <VStack
                        aspectRatio={"auto"}
                        justifyContent={"center"}
                        display={"flex"}
                        alignItems={"center"}
                        h={"full"}
                        w={"full"}
                        px={10}
                        py={2}
                      >
                        <Text size="md"> {item.viewValue}</Text>
                      </VStack>
                    </RadioCard>
                  </Box>
                </VStack>
              );
            })}
          </Flex>
        </FormControl>
        <Box
          w={"full"}
          flex={1}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {getValues("tarif") === "base" && (
            <VStack
              spacing={4}
              align="start"
              p={4}
              borderRadius="md"
              mx={"auto"}
            >
              <FormControl display="flex" alignItems="center" mx={"auto"}>
                <FormLabel as={"label"}>
                  {" "}
                  <Text>base</Text>
                </FormLabel>
                <Controller
                  name="base.base"
                  control={control}
                  render={({ field }) => (
                    <PrimaryInput
                      type="number"
                      width="150px"
                      isRequired
                      {...field}
                      value={field.value || ""} // Évite les erreurs d'entrée non contrôlée
                      onChange={(e) => field.onChange(Number(e.target.value))}
                    />
                  )}
                />
              </FormControl>
            </VStack>
          )}

          {/* 4CADRANS */}
          {getValues("tarif") === "hp_hc" && (
            <VStack
              spacing={4}
              align="start"
              p={2}
              width={"full"}
              maxW={"fit-content"}
            >
              <Grid
                templateColumns="repeat(2, 1fr)"
                mx="auto"
                gap={{ sm: 4, md: 10 }} // Moved rowGap & columnGap here
              >
                {["hp", "hc"].map((field) => (
                  <GridItem key={field} colSpan={{ base: 2, md: 1 }}>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel as={"label"}>
                        {" "}
                        <Text>{`Tarif ${field.toUpperCase()}`}</Text>
                      </FormLabel>
                      <Controller
                        name={`hp_hc.${field}`}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            isRequired
                            {...controllerField}
                            value={controllerField.value || ""} // Évite les erreurs d'entrée non contrôlée
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}

          {/* 4CADRANS */}
          {getValues("tarif") === "4cadrans" && (
            <VStack spacing={4} align="start">
              <Grid
                templateRows="repeat(2, auto)"
                templateColumns="repeat(4, 1fr)"
                mx="auto"
                p={2}
                gap={{ sm: 4, md: 10 }} // Moved rowGap & columnGap here
              >
                {["hcbs", "hchs", "hpbs", "hphs"].map((field) => (
                  <GridItem key={field} colSpan={{ base: 2, md: 1 }}>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel as={"label"}>
                        {" "}
                        <Text>{`Tarif ${field.toUpperCase()}`}</Text>
                      </FormLabel>
                      <Controller
                        name={`4cadrans.${field}`}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            isRequired
                            {...controllerField}
                            value={controllerField.value || ""} // Évite les erreurs d'entrée
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}

          {/* 5CADRANS */}
          {getValues("tarif") === "5cadrans" && (
            <VStack spacing={4} align="start" p={2}>
              <Grid
                templateRows="repeat(2, auto)"
                templateColumns="repeat(4, 1fr)"
                mx="auto"
                p={2}
                gap={{ sm: 4, md: 10 }} // Moved rowGap & columnGap here
              >
                {["hcbs", "hchs", "hpbs", "hphs", "hp"].map((field) => (
                  <GridItem key={field} colSpan={{ base:2, md: 1 }} >
                    <FormControl display="flex" alignItems="center">
                      <FormLabel as={"label"}>
                        {" "}
                        <Text>{`Tarif ${field.toUpperCase()}`}</Text>
                      </FormLabel>
                      <Controller
                        name={`5cadrans.${field}`}
                        control={control}
                        render={({ field: controllerField }) => (
                          <PrimaryInput
                            type="number"
                            width="150px"
                            {...controllerField}
                            isRequired
                            value={controllerField.value || ""} // Prevents uncontrolled input issues
                            onChange={(e) =>
                              controllerField.onChange(Number(e.target.value))
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </GridItem>
                ))}
              </Grid>
            </VStack>
          )}
        </Box>
        <Flex flexDir={"row"} justifyContent={"end"} m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Flex>
      </form>
    </Box>
  );
};
