import React from "react";
import { Box, useRadio, UseRadioProps } from "@chakra-ui/react";

// Définition des types pour les props de RadioCard
interface RadioCardProps extends UseRadioProps {
  children: React.ReactNode;
}

// Composant personnalisé RadioCard
export const RadioCard: React.FC<RadioCardProps> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label">
      <input {...input} hidden />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        boxShadow="0px 5px 10px rgba(0, 0, 0, 0.15)"
        textAlign="center"
        bg="secondary.500"
        color="primary.50"
        border="none"
        fontWeight="bold"
        transition="all 0.2s ease-in-out"
        outline="none"
        _checked={{
          bg: "primary.500",
          color: "secondary.500",
          transform: "scale(1.05)", // Effet d'agrandissement au check
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.25)",
        }}
        _hover={{
          bg: "primary.500",
          color: "secondary.500",
          transform: "scale(1.05)", // Effet d'agrandissement au check
          boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.25)",
        }}
        _focus={{
          boxShadow: "0px 0px 5px rgba(45, 106, 79, 0.8)",
        }}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};
