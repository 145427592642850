import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
interface PrimaryButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button
      bg="primary.500"
      color="primary.50"
      fontSize="lg"
      fontWeight="bold"
      px={6}
      py={5}
      borderRadius="md"
      boxShadow={"0px 4px 10px rgba(0, 0, 0, 0.2)"}
      _hover={{
        bg: "primary.600",
        transform: "scale(1.05)",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      }}
      _active={{
        transform: "scale(0.95)",
      }}
      transition="all 0.2s ease-in-out"
      {...props}
    >
      {children}
    </Button>
  );
};
