/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react"; 
import { 
  fetchBaseQuery,
} from "@reduxjs/toolkit/query"; 
const BASE_URL="https://api-adresse.data.gouv.fr/search/"
export interface FeatureCollection {
  type:string;
  features : Feature[]
}
export interface Feature {
  type:string;
  geometry: Geometry;
  properties:Properties
}
export interface Geometry {
  type:string;
  coordinates:number[]
}
export interface Properties {
  [key:string]:string
}
export const geoLocalisationApi = createApi({
  reducerPath: "geoLocalisationApi",
  baseQuery:fetchBaseQuery({
    baseUrl: BASE_URL,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prepareHeaders: (headers: any) => {
      headers.set("Accept", "application/json");
      return headers;
    }, 
  }),
  endpoints: (builder) => ({
    searchLocalisation: builder.query<FeatureCollection, { params: string }>({
      query: ({ params}) => ({
        url: `?${params}`,
        method: "GET"
      }),
    })
})});

export const { 
useLazySearchLocalisationQuery,useSearchLocalisationQuery
} = geoLocalisationApi;
