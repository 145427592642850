import { Grid, GridItem } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import { NavBar } from "../components/NavBar";
import { Header } from "../components/Header";
interface GlobalLayoutProps {
  children?: ReactNode;
}
export const GlobalLayout: React.FC<GlobalLayoutProps> = () => {
  return (
    <Grid
      templateRows="repeat(12, 1fr)"
      templateColumns="repeat(12, 1fr)"
      height={"100vh"}
    >
      <GridItem
        rowSpan={{
          base: 1,
          md: 1,
          lg: 1,
        }}
        colSpan={{
          base: 12,
          md: 12,
          lg: 12,
        }}
      >
        <Header />
      </GridItem>
      <GridItem
        rowSpan={{
          base: 2,
          md: 11,
          lg: 11,
        }}
        colSpan={{
          base: 12,
          md: 3,
          lg: 1,
        }}
      >
        <NavBar />
      </GridItem>

      <GridItem
        rowSpan={{
          base: 9,
          md: 11,
          lg: 11,
        }}
        colSpan={{
          base: 12,
          md: 9,
          lg: 11,
        }}
        bg={"gray.100"}
        overflowY={"auto"}
        py={4}
      >
        <Outlet />
      </GridItem>
    </Grid>
  );
};
