import { Box, Button, Flex } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
interface NavBarProps {
  children?: ReactNode;
}
interface TabItem {
  text: string;
  active: boolean;
  onClick: (item: TabItem) => void;
  link: string;
}
export const NavBar: React.FC<NavBarProps> = () => {
  const navigate = useNavigate();
  const onClick = (item: TabItem) => {
    setItems((prev: TabItem[]) => {
      const newItems: TabItem[] = [];
      prev.forEach((elt) => {
        if (item.text == elt.text) {
          newItems.push({
            ...elt,
            active: true,
          });
        } else {
          newItems.push({
            ...elt,
            active: false,
          });
        }
      });
      return newItems;
    });
    navigate(item.link);
  };
  const initialItems: TabItem[] = [
    {
      text: "Consommation",
      link: "/consumption",
      active: true,
      onClick,
    },
    {
      text: "Installation PV",
      link: "/installationPv",
      active: false,
      onClick,
    }, 
    {
      text: "Autoconsommation",
      link: "/autoconsommation",
      active: false,
      onClick,
    },
    {
      text: "Installation complète",
      link: "/installationComplete",
      active: false,
      onClick,
    },
    {
      text: "Bilan Economique",
      link: "/bilanEconomique",
      active: false,
      onClick,
    },
    {
      text: "Étude écologique",
      link: "/etudeEcologique",
      active: false,
      onClick,
    },
    {
      text: "Rapport",
      link: "/rapport",
      active: false,
      onClick,
    },
  ];
  const [items, setItems] = useState<TabItem[]>(initialItems);

  return (
    <Flex
      flexDir={{
        base: "row",
        md: "column",
      }}
      wrap={{
        base: "wrap",
        md: "wrap",
      }}
      gap={{
        base: 2,
        md: 4,
      }}
      justifyContent={{
        base: "start",
      }}
      as="nav" 
      bg={"primary.500"}
      h={"full"}
    >
      {items.map((item, index) => (
        <TabItem
          active={item.active}
          link={item.link}
          onClick={() => item.onClick(item)}
          key={index}
        >
          {item.text}
        </TabItem>
      ))}
    </Flex>
  );
};
interface TabItemProps {
  active: boolean;
  link: string;
  children?: ReactNode;
  onClick?: () => void;
}
const TabItem: React.FC<TabItemProps> = ({
  active,
  link,
  children,
  onClick,
}) => {
  return (
    <Button
      as={NavLink}
      to={link}
      _hover={{
        bg: "none",
        color: "secondary.500",
      }}
      color={active ? "secondary.500" : "gray.500"}  // Change color based on active state
      w="full"
      borderRadius="none"
      variant={"ghost"}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      maxW={"fit-content"}
      fontSize={{ base: "sm", md: "xl" }}
    >
      {children}
    </Button>
  );
};
