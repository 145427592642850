import React from "react";
import {
  Box,
  Grid,
  FormLabel,
  Heading,
  Text,
  useRadioGroup,
  VStack,
  Flex,
  HStack,
  FormControl,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { PrimaryInput } from "../components/PrimaryInput";
import { RadioCard } from "../components/RadioCard";
import { PrimaryButton } from "../components/PrimaryButton";

interface Option {
  value: string;
  viewValue: string;
  description: string;
}

const INSTALLATION_OPTIONS: Option[] = [
  {
    value: "small",
    viewValue: "Petite",
    description:
      "Installation avec un rendement Maximal pour un petit investissement",
  },
  {
    value: "optimal",
    viewValue: "Optimale",
    description:
      "Installation qui cherche le compromis optimal entre rentabilité et investissement",
  },
  {
    value: "large",
    viewValue: "Grande",
    description:
      "Installation qui maximise les gains sur la vie de l’installation",
  },
];

const RadioGroupField: React.FC<{
  name: string;
  options: Option[];
  defaultValue: string;
  setValue: (name: string, value: string) => void;
}> = ({ name, options, defaultValue, setValue }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value) => setValue(name, value),
  });

  return (
    <Flex
      {...getRootProps()}
      gap={{ base: 4, md: 10 }}
      justifyContent={{ base: "center", md: "space-around" }}
      w={"full"}
      wrap={{
        base: "wrap",
        md: "nowrap",
      }}
    >
      {options.map((option) => (
        <VStack key={option.value}>
          <RadioCard {...getRadioProps({ value: option.value })}>
            <Box
              aspectRatio={"auto"}
              justifyContent={"center"}
              display={"flex"}
              alignItems={"center"}
              h={"100px"}
              w={"100px"}
            >
              <Text size="md">{option.viewValue}</Text>
            </Box>
          </RadioCard>
          <Text>{option.description}</Text>
        </VStack>
      ))}
    </Flex>
  );
};

export const InstallationCompletePage: React.FC = () => {
  const { control, handleSubmit, setValue } = useForm<any>();

  const onSubmit = (data: any) => alert(JSON.stringify(data));

  return (
    <Box p={4} flex={1} h={"full"}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          height: "100%",
          gap:12,
        }}
      >
        <FormControl as="fieldset" gap={2}>
          <FormLabel>
            <Heading variant="primary" size={{ base: "sm", md: "md" }}>
              Type installation
            </Heading>
          </FormLabel>
          <RadioGroupField
            name="installlation"
            options={INSTALLATION_OPTIONS}
            defaultValue="optimal"
            setValue={setValue}
          />{" "}
        </FormControl>
        <Grid
          templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          templateRows={"auto"}
          gap={4}
          py={2}
        >
          {[
            "batteryCapacity",
            "batteryPower",
            "pvPower",
            "batteryPrice",
            "pvPrice",
          ].map((field) => (
            <React.Fragment key={field}>
              <FormLabel w={"full"}>
                <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                  {field === "batteryCapacity"
                    ? "Capacité Batterie"
                    : field === "batteryPower"
                    ? "Puissance Batterie"
                    : field === "pvPower"
                    ? "Puissance PV"
                    : field === "batteryPrice"
                    ? "Prix estimé batterie"
                    : field === "pvPrice"
                    ? "Prix estimé PV"
                    : ""}
                </Heading>
              </FormLabel>
              <Box>
                <Controller
                  name={field}
                  control={control}
                  render={({ field: controllerField }) => (
                    <PrimaryInput
                      type="number"
                      width="150px"
                      {...controllerField}
                      value={controllerField.value || ""}
                      onChange={(e) =>
                        controllerField.onChange(Number(e.target.value))
                      }
                    />
                  )}
                />
              </Box>
            </React.Fragment>
          ))}
        </Grid>
        <Box display="flex" justifyContent="end" m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
