import { Routes, Route } from "react-router-dom";
import React from "react";
import { GlobalLayout } from "../layouts/GlobalLayout";
import { ConsumptionPage } from "../pages/ConsumptionPage";
import { ProductionPage } from "../pages/ProductionPage";
import { AutoConsommationPage } from "../pages/AutoConsommationPage";
import { InstallationCompletePage } from "../pages/InstallationCompletePage";
import { BilanEconomiquePage } from "../pages/BilanEconomiquePage";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Route "Connexion" */}

      <Route element={<GlobalLayout />}>
        <Route path="/consumption" element={<ConsumptionPage />} />
        <Route path="/installationPv" element={<ProductionPage />} />
        <Route path="/autoconsommation" element={<AutoConsommationPage />} />
        <Route path="/installationComplete" element={<InstallationCompletePage />} />
        <Route path="/bilanEconomique" element={<BilanEconomiquePage />} />
        <Route path="*" element={<ConsumptionPage />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
