import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormLabel,
  Heading,
  Text,
  useRadioGroup,
  VStack,
  Flex,
  Select,
  GridItem,
  HStack,
} from "@chakra-ui/react";
import { Control, Controller, useForm } from "react-hook-form";
import { PrimaryInput } from "../components/PrimaryInput";
import { RadioCard } from "../components/RadioCard";
import { PrimaryButton } from "../components/PrimaryButton";
import {
  Feature,
  useLazySearchLocalisationQuery,
} from "../services/geoLocalisationApi";
import { getLoadingDataErrorByStatus } from "../utils/toolbox";
import EmsAlert from "../components/EmsAlert";
import { PrimarySelect } from "../components/PrimarySelect";

interface Option {
  value: string;
  viewValue: string;
}

const ORIENTATION_OPTIONS: Option[] = [
  { value: "nord", viewValue: "Nord" },
  { value: "sud", viewValue: "Sud" },
  { value: "est", viewValue: "Est" },
  { value: "ouest", viewValue: "Ouest" },
];

const ANGLE_OPTIONS: Option[] = [
  { value: "plat0", viewValue: "À plat 0°" },
  { value: "faible", viewValue: "Faible" },
  { value: "standard", viewValue: "Standard 24°" },
  { value: "façade", viewValue: "Sur façade" },
];

const RadioGroupField: React.FC<{
  name: string;
  options: Option[];
  defaultValue: string;
  setValue: (name: string, value: string) => void;
}> = ({ name, options, defaultValue, setValue }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange: (value) => setValue(name, value),
  });

  return (
    <Flex
      {...getRootProps()}
      gap={{ base: 4, md: 10 }}
      justifyContent={{ base: "center", md: "start" }}
      wrap={"wrap"}
    >
      {options.map((option) => (
        <VStack key={option.value}>
          <RadioCard {...getRadioProps({ value: option.value })}>
            <Box
              aspectRatio={"auto"}
              justifyContent={"center"}
              display={"flex"}
              alignItems={"center"}
              h={"100px"}
              w={"100px"}
            >
              <Text size="md">{option.viewValue}</Text>
            </Box>
          </RadioCard>
        </VStack>
      ))}
    </Flex>
  );
};

type VilleDropdownProps = {
  control: Control<any>;
  error?: { status?: number };
};
const VilleDropdown: React.FC<VilleDropdownProps> = ({ control }) => {
  const [text, setText] = useState("");
  const [features, setFeatures] = useState<Feature[]>([]);
  const [searchLocation, { error }] = useLazySearchLocalisationQuery();
  useEffect(() => {
    if (text.length >= 3) {
      searchLocation({ params: `q=${text}&type=municipality` })
        .unwrap()
        .then((res) => {
          setFeatures(res.features);
        });
    } else {
      setFeatures([]);
    }
  }, [text]);
  return (
    <VStack>
      <HStack>
        <Grid
          templateColumns={{ base: "1fr", md: "auto 1fr" }}
          gap={{ base: 4, md: 10 }}
          alignItems="start"
          justifyContent={"start"}
          justifyItems={{
            base: "center",
            md: "start",
          }}
        >
          <React.Fragment>
            <FormLabel>
              <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                CodePostal/Ville
              </Heading>
            </FormLabel>
            <Controller
              name={"ville"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <HStack
                  wrap={"wrap"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  justifyItems={"center"}
                >
                  <PrimaryInput
                    placeholder="Code postal ou ville"
                    value={text || ""}
                    onChange={(e) => {
                      setText(e.target.value);
                    }}
                    width="200px"
                  />
                  <PrimarySelect
                    name="ville"
                    value={value || ""}
                    onChange={(e) => onChange(e.target.value)}
                    width="200px"
                    options={features.map((feature) => ({
                      key: feature.properties.id,
                      value: feature.properties.city,
                      viewValue: feature.properties.city,
                    }))}
                  />
                  {features && features.length == 0 && (
                    <Text>Aucune ville trouvée</Text>
                  )}
                  {features && features.length > 0 && (
                    <Text> {features.length} ville(s) trouvée(s)</Text>
                  )}
                  {error && (
                    <EmsAlert
                      status="error"
                      title={
                        ("status" in error
                          ? getLoadingDataErrorByStatus(error.status).titre
                          : getLoadingDataErrorByStatus(undefined).titre) +
                        " Lors du chargement des villes"
                      }
                      description={
                        "status" in error
                          ? getLoadingDataErrorByStatus(error.status).message
                          : getLoadingDataErrorByStatus(undefined).message
                      }
                    />
                  )}
                </HStack>
              )}
            />
          </React.Fragment>
        </Grid>
      </HStack>
    </VStack>
  );
};
export const ProductionPage: React.FC = () => {
  const { control, handleSubmit, setValue, getValues } = useForm<any>();
  const onSubmit = (data: any) => alert(JSON.stringify(data));
  return (
    <Box p={4} flex={1}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          templateColumns={{ base: "1fr", md: "auto 1fr" }}
          gap={{ base: 4, md: 10 }}
          alignItems="start"
          justifyContent={"start"}
          justifyItems={{
            base: "start",
            md: "start",
          }}
        >
          <GridItem colSpan={2} w={"full"} justifyItems={"start"}>
            <VilleDropdown control={control} />
          </GridItem>
          {["tailleToiture", "power"].map((field) => (
            <React.Fragment key={field}>
              <FormLabel>
                <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                  {field === "tailleToiture"
                    ? "Taille Toiture m²"
                    : "Puissance"}
                </Heading>
              </FormLabel>
              <Controller
                name={field}
                control={control}
                render={({ field: controllerField }) => (
                  <PrimaryInput
                    type="number"
                    width="150px"
                    isRequired
                    {...controllerField}
                    value={controllerField.value || ""}
                    onChange={(e) => {
                      controllerField.onChange(e.target.value);
                    }}
                  />
                )}
              />
            </React.Fragment>
          ))}
          <React.Fragment>
            <FormLabel>
              <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                Orientation des PV
              </Heading>
            </FormLabel>
            <RadioGroupField
              name="orientation"
              options={ORIENTATION_OPTIONS}
              defaultValue="nord"
              setValue={setValue}
            />
          </React.Fragment>
          <React.Fragment>
            <FormLabel>
              <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                Angle Toiture
              </Heading>
            </FormLabel>
            <RadioGroupField
              name="toiture"
              options={ANGLE_OPTIONS}
              defaultValue="plat0"
              setValue={setValue}
            />
          </React.Fragment>
        </Grid>
        <Box display="flex" justifyContent="end" m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
