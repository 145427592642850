import { createContext, useContext, useState, ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { createTheme, ThemeName } from "../theme";
import React from "react";
interface ThemeContextProps {
  themeName: ThemeName;
  switchTheme: (newTheme: ThemeName) => void;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const [themeName, setThemeName] = useState<ThemeName>("forest");

  const switchTheme = (newTheme: ThemeName) => {
    setThemeName(newTheme);
  };

  return (
    <ThemeContext.Provider value={{ themeName, switchTheme }}>
      <ChakraProvider theme={createTheme(themeName)}>{children}</ChakraProvider>
    </ThemeContext.Provider>
  );
};

// Hook personnalisé pour accéder au contexte
export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
