import { COLORS, formatter } from "../utils/toolbox";
import { DoghnutData, DoughnutGraph } from "./DoughnutGraph";
import { LabelWithValue } from "./LabelWithValue";
import React, { memo } from "react";
import { Box } from "@chakra-ui/react";
import { Flex, Heading, Text, VStack } from "@chakra-ui/react";

interface AutoConsommationIndicatorComponentProps {
  title: string;
  graphData: DoghnutData;
  energyDistribution: EnergyDistribution[];
}
export interface EnergyDistribution {
  value: string;
  color: COLORS;
  label: string;
}
const AutoConsommationIndicatorComponent: React.FC<
  AutoConsommationIndicatorComponentProps
> = ({ title, graphData, energyDistribution }) => {
  return (
    <Box w={"full"} h={"full"}>
      <VStack  w={"full"} h={"full"}  gap={4} justifyContent={"start"}>
        <Heading
         variant={"primary"}
         textAlign={"center"}
         size={{ base: "sm", md: "md" }}
        >{title}</Heading>
        <VStack  w={"full"} h={"full"} gap={4} justifyContent={"space-around"}>
          <Box 
          flex={1}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          width={"full"}
          >
            <DoughnutGraph
              formatter={formatter("kWh")}
              backgroundColor={graphData.backgroundColor}
              labels={graphData.labels}
              datas={graphData.datas}
            />
          </Box>

          <Flex wrap={"wrap"}>
            {energyDistribution.map((elt, index) => {
              return <LabelWithValue key={index} {...elt} />;
            })}
          </Flex>
        </VStack>
      </VStack>
    </Box>
  );
};
export const AutoConsommationIndicator = memo(
  AutoConsommationIndicatorComponent
);
