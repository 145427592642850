import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

// Enregistrer les composants nécessaires
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const WaterfallChart = () => {
  // Données brutes (modifications de facture)
  const rawData = [
    { name: "Situation Initiale", value: 25000 },
    { name: "PV 100 kWc", value: -5000 },
    { name: "Batterie 100 kWh", value: -7000 },
    { name: "TarifRéseau+Câbles", value: -4000 },
    { name: "EPS SPEN", value: -2000 },
    { name: "Total", value: 5000 },
  ];

  // Calculer les valeurs cumulées
  let cumulative = 0;
  const data = rawData.map((item) => {
    cumulative += item.value;
    return { name: item.name, cumulative, value: item.value };
  });

  // Données pour Chart.js
  const chartData = {
    labels: data.map((d) => d.name),
    datasets: [
      {
        label: "Facture (€)",
        data: data.map((d) => d.cumulative), // Valeurs cumulées pour l'effet cascade
        backgroundColor: data.map((d, i) => (i === 0 || i === data.length - 1 ? "blue" : "teal")), // Couleurs
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: true, text: "Évolution de la facture annuelle (€)" },
    },
    scales: {
      y: { beginAtZero: true },
    },
  };

  return <Bar data={chartData} options={options} />;
};

export default WaterfallChart;
