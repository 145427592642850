import { combineReducers } from "redux";
import { calculatorReducer } from "../store/config";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { geoLocalisationApi } from "../services/geoLocalisationApi";
import { calculatorServiceApi } from "../services/calculatorService";

const rootReducer = combineReducers({
  calculator: calculatorReducer,
  [geoLocalisationApi.reducerPath]: geoLocalisationApi.reducer,
  [calculatorServiceApi.reducerPath]: calculatorServiceApi.reducer,

});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
  .concat(geoLocalisationApi.middleware)
  .concat(calculatorServiceApi.middleware)
});

setupListeners(store.dispatch);
/*
const unsubscribe = store.subscribe(() =>
  console.log('State after dispatch: ', store.getState())
) 
*/
export type RootState = ReturnType<typeof store.getState>;
    