import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/Routes";
import { Provider } from "react-redux";
import { store } from "./reducers/reducer";
import { ThemeProvider } from "./contexts/ThemeContext";

function App() {
  return (
        <ThemeProvider>
        <Provider store={store}>
        <Router>
          <AppRoutes />
        </Router>
      </Provider>
        </ThemeProvider>
  );
}

export default App;
