import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, store } from '../reducers/reducer';
import { setupListeners } from '@reduxjs/toolkit/query';
export interface CalculatorData {
    solarPanelInfo : {
        power : number;
        angle : number;
        orientation:number
    },
    batteryInfo : {
        power : number;
        capacity:number;
        efficiency:number
    },
    installationInfo: {
        latitude : number;
        longitude : number;
        type_tarif:string|null;
        start_hc : number;
        end_hc :number;
        start_hc2 : number;
        end_hc2 : number;
        prices : {
          base? : number;
          hphc?:{
            hp?:number;
            hc?:number;
          };
          ForCADRANS?:{
            HPHS?:number;
            HCHS?:number;
            HPBS?:number;
            HCBS?:number;
          };
          FiveCADRANS?:{ 
            PTE?:number;
          }
        } | null; 
        conso_moy_annuelle:number
    }
}
const initialState : CalculatorData = {
  solarPanelInfo : {
      power : 0,
      angle : 0,
      orientation:0
  },
  batteryInfo: {
      power : 0,
      capacity:0,
      efficiency:0
  },
  installationInfo : {
      latitude : 0,
      longitude : 0,
      start_hc : 0,
      end_hc : 0,
      start_hc2 : 0,
      end_hc2 : 0,
      prices: null,
      conso_moy_annuelle:0,
      type_tarif:null
  }
}
const calculatorSlice = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    setSolarPanelInfo(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['solarPanelInfo']>> ) {
      state={
        ...state, solarPanelInfo : {
          ...state.solarPanelInfo,
          ...action.payload
        }
      }
      return state
    },
    setBatteryInfo(state: CalculatorData, action: PayloadAction<Partial<CalculatorData['batteryInfo']>>) {
        state={
          ...state, batteryInfo : {
            ...state.batteryInfo,
            ...action.payload
          }
        }
        return state
      } ,
      setInstallationInfo(state: CalculatorData, action:PayloadAction<Partial<CalculatorData['installationInfo']>>) { 
        state={
          ...state, installationInfo : {
            ...state.installationInfo,
            ...action.payload
          }
        }
        return state
      } ,
  },
})
export const { setBatteryInfo,setInstallationInfo,setSolarPanelInfo } = calculatorSlice.actions
export const calculatorReducer = calculatorSlice.reducer
export const selectCalculatorData = (state: RootState) => state.calculator