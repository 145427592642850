/* eslint-disable @typescript-eslint/no-explicit-any */
import { Doughnut } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { Chart, registerables } from 'chart.js';

import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(...registerables);
Chart.register(annotationPlugin); 
interface DoughnutGraphProps {
    backgroundColor?: Array<string>;
    labels?: Array<string>;
    datas?: Array<number>;
    formatter? :  (value: number, context: any)=>any
}
export interface DoghnutData {
  backgroundColor: string[];
  labels: string[];
  datas: number[] | undefined;
}
export const DoughnutGraph: React.FC<DoughnutGraphProps> = ({ backgroundColor = [], labels = [], datas = [],formatter}) => {
    
const plugins: any = {
    tooltip: {
        enabled: true,
    },
    datalabels: {
        enabled: true
    }
}

const options = {
    cutout: 0, // Remplit complètement le centre
    hoverOffset: 4,
    borderWidth: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
          },
        datalabels: {
            color: 'white',
            // Permet d'éviter que les labels se chevauchent si les segments sont trop petits.
            display: 'auto',
            formatter 
        },
    }
}
    const [data, setData] = useState<any | null>(null);



    useEffect(() => {
            setData({
                labels: labels,
                datasets: [
                    {                        
                        data: datas,
                        backgroundColor: backgroundColor
                    },
                ],
            });
    }, [labels,datas ]);

    return data ? (
        <Doughnut 
            data={data} 
            plugins={plugins} 
            options={options}
            height={"full"}
            width={"full"}
            
        />
    ) : (
        <div>Chargement...</div>
    );
};
