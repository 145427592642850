import React from "react";
import {
  Box,
  Grid,
  FormLabel,
  Heading, 
  FormControl,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { PrimaryInput } from "../components/PrimaryInput";
import { PrimaryButton } from "../components/PrimaryButton";
import WaterfallChart from "../components/WaterfallChart";
 
export const BilanEconomiquePage: React.FC = () => {
  const { control, handleSubmit, setValue } = useForm<any>();

  const onSubmit = (data: any) => alert(JSON.stringify(data));

  return (
    <Box p={4} flex={1} h={"full"}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          height: "100%",
          gap:12,
        }}
      >
        <Grid
          templateColumns={{ base: "1fr 1fr", md: "1fr 1fr 1fr 1fr" }}
          templateRows={"auto"}
          gap={4}
          py={2}
        >
          {[
            "investissementInitial",
            "pret",
            "tauxInteret",
            "roi",
            "gainCumule",
            "inflation",
            "triProject"
          ].map((field) => (
            <React.Fragment key={field}>
              <FormLabel w={"full"}>
                <Heading variant="primary" size={{ base: "sm", md: "md" }}>
                  {field === "investissementInitial"
                    ? "Investissement initial"
                    : field === "pret"
                    ? "Prêt"
                    : field === "tauxInteret"
                    ? "Taux d’interet"
                    : field === "roi"
                    ? "ROI"
                    : field === "gainCumule"
                    ? "Gains cumulés":
                    field =="inflation" ? "Inflation"
                    : field =="triProject" ? "TRI Projet"
                    : ""}
                </Heading>
              </FormLabel>
              <Box>
                <Controller
                  name={field}
                  control={control}
                  render={({ field: controllerField }) => (
                    <PrimaryInput
                      type="number"
                      width="150px"
                      {...controllerField}
                      value={controllerField.value || ""}
                      onChange={(e) =>
                        controllerField.onChange(Number(e.target.value))
                      }
                    />
                  )}
                />
              </Box>
            </React.Fragment>
          ))}
        </Grid>
        <Box>
            <WaterfallChart/>
        </Box>
        <Box display="flex" justifyContent="end" m={2}>
          <PrimaryButton
            type="submit"
            px={{ base: 8, md: 16 }}
            py={{ base: 4, md: 8 }}
          >
            Suivant
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  );
};
